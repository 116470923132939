<template lang="">
  <b-modal v-model="showUserSubsViewModal" centered scrollable title="Your Subscription Plan" hide-footer no-close-on-backdrop no-close-on-esc @hidden="emitOpenCloseUserSubsViewModal">
    <iq-card  body-class="text-center subPlanRow">
      <template v-slot:body>
        <span class="font-size-16 text-uppercase" v-if="subsPlan.plan !== ''">{{ subsPlan.subs_plan_name }}</span>
        <h2 class="mb-4 font-weight-bolder" :class="subsPlan.active ? 'text-white' : ''">
          {{subsPlan.currency_symbol}} {{ subsPlan.subs_plan_price }}
          <small class="font-size-14 " :class="subsPlan.active ? 'text-white' : 'text-muted'">/{{subsPlan.subs_plan_interval.replace('ly', '')}}</small>
        </h2>
        <!-- <li class="list-unstyled">{{subsPlan.subs_plan_desc}}</li> -->
        <div class="col-md-12 modules text-left pl-4">
          <p class="moduleHeading m-0" >
            Details
          </p>
        </div>
        <div>
          <div class="col-md-12 moduleslists" >
            <ul>
              <li v-for="(subsModule, indexM) in utility.splitTextBy(subsPlan.subs_plan_desc, '.')" :key="indexM+'B'">
                {{ subsModule }}
              </li>
              <li>Status: &nbsp; {{subsPlan.subscription_status}}</li>
              <li>Plan Starts On : &nbsp; {{moment.unix(subsPlan.subs_start_on).format("YYYY-MM-DD")}}</li>
              <li>Plan Ends On : &nbsp; {{moment.unix(subsPlan.subs_end_on).format("YYYY-MM-DD")}}</li>
              <li v-for="(val, key) in subsRules" :key="key">{{key}} : &nbsp; {{ val == 1 ? 'Enabled' : val == 0 ? 'Disabled' : val}}</li>
            </ul>
          </div>
        </div>
      </template>
    </iq-card>
  </b-modal>
</template>
<script>
import Utility from "../Utils/utility"
import moment from "moment"
export default {
  name: "UserSubscriptionView",
  props: {
    propShowUserSubsViewModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    myPlan () {
      return this.$store.getters["Paygw/myPlanState"]
    }
  },
  data () {
    return {
      moment: moment,
      showUserSubsViewModal: this.propShowUserSubsViewModal,
      subsPlan: {},
      subsRules: {},
      utility: Utility
    }
  },
  methods: {
    /**
     * emitOpenCloseUserSubsViewModal
     */
    emitOpenCloseUserSubsViewModal () {
      this.$emit("emitOpenCloseUserSubsViewModal")
    }
  },
  async mounted () {
    const planDetails = await this.myPlan
    if (planDetails) {
      this.subsPlan = planDetails
      if (this.subsPlan.subs_rules) {
        if (typeof this.subsPlan.subs_rules == "string") {
          this.subsRules = JSON.parse(this.subsPlan.subs_rules)
        }
        else {
          this.subsRules = this.subsPlan.subs_rules
        }
      }
    }
  },
  watch: {
    async propShowUserSubsViewModal () {
      this.showUserSubsViewModal = this.propShowUserSubsViewModal
    }
  }
}
</script>
<style lang="">
</style>
